<template>
  <div class="accounts__page">
    <Redirect title="Settings" route="website.settings" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">Account</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <Subscription />
    <v-divider></v-divider>
    <v-row no-gutters class="py-4">
      <v-col cols="12" sm="4" class="">
        <h3>Account and permissions</h3>
      </v-col>
      <v-col cols="12" sm="8" class="flex-column">
        <v-row class="elevation-form account-owner pa-3">
          <v-col cols="12">
            <h3>Account owner</h3>
          </v-col>
          <v-col cols="12" class=" form-component d-flex justify-space-between">
            <div class="d-flex">
              <div>
                <v-avatar size="40" color="red">
                  <img
                    src="https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg"
                    alt="alt"
                  />
                </v-avatar>
              </div>
              <div v-if="accountOwner" class=" ml-3">
                <span class="link-add" @click="viewDetail(accountOwner)">
                  {{ user && accountOwner.id !== user.id ? accountOwner.email : 'You' }}
                </span>
                <div class="fz-12">{{ accountOwner.memberSince }}</div>
              </div>
            </div>
            <!-- <div class="link-add">View your profile</div> -->
          </v-col>
        </v-row>
        <v-row
          class="elevation-form mt-4 staff-owner pa-3 form-component"
          v-if="listStoreStaffs && listStoreStaffs.length === 0"
        >
          <div>
            <v-col cols="12">
              Customize what your staff members can edit and access.
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="$router.push({ name: 'website.settings.account.add' })"
                >Add staff account
              </v-btn>
            </v-col>
          </div>
        </v-row>
        <v-row class="elevation-form mt-4 staff-owner pa-3 form-component" v-else>
          <v-col cols="12" class="d-flex align-center">
            <h3>Staff accounts</h3>
            <v-spacer></v-spacer>
            <div v-if="accountOwner && user && accountOwner.id === user.id">
              <span class="link-add mr-4" @click="showModalLogout = true">Log out all staff accounts</span>
              <span @click="$router.push({ name: 'website.settings.account.add' })" class="link-add"
                >Add staff account</span
              >
            </div>
          </v-col>
          <v-col
            v-for="item in listStoreStaffs"
            :key="item.id"
            cols="12"
            class=" form-component d-flex justify-space-between"
          >
            <div class="d-flex">
              <div>
                <v-avatar size="40" color="red">
                  <img
                    src="https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg"
                    alt="alt"
                  />
                </v-avatar>
              </div>
              <div class=" ml-3">
                <span @click="viewDetail(item)" class="link-add">{{
                  user && item.id !== user.id ? item.email : 'You'
                }}</span>
                <!-- <div class="fz-12">Invitation Sent</div> -->
              </div>
            </div>
            <!-- <pre>{{ item }}</pre> -->
            <div class="fz-12">
              {{ item.isStoreAdmin ? 'Full access' : 'Limited access' }}
            </div>
            <!-- <div class="fz-12" v-else>
              {{ item.isStoreAdmin ? 'Full access' : 'Limited access' }}
            </div> -->
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="$router.push({ name: 'website.settings.account.add' })"
              >Add staff account
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <modal model="showModalLogout" title="Log out all staff accounts" :callback="submitLogoutAllStaff">
        <template slot="content">
          <small class="input-description">
            Are you sure you want to log out all staff accounts ?
          </small>
        </template>
      </modal>
    </v-row>
  </div>
</template>
<script>
import Redirect from '@/components/RedirectTo';
import Subscription from './Subscription.vue';
import { authService } from '@/apis/permission';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    Redirect,
    Subscription,
  },
  data() {
    return {
      showModalLogout: false,
      listStoreStaffs: [],
      accountOwner: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    viewDetail(staff) {
      if (this.user.id !== staff.id) {
        this.$router.push({ name: 'website.settings.account.update', params: { id: staff.id } });
      } else {
        this.$router.push({ name: 'website.profile' });
      }
    },
    submit() {},
    async getStoreStaffs() {
      try {
        let res = await authService.getStoreStaffs();
        this.listStoreStaffs = res.data;
        let indexOwner = this.listStoreStaffs.findIndex(item => item.isStoreOwner === true);
        this.accountOwner = this.listStoreStaffs[indexOwner];
        this.listStoreStaffs.splice(indexOwner, 1);
      } catch (error) {
        console.log(error);
      }
    },
    async submitLogoutAllStaff(data) {
      try {
        authService.logoutAddStaffAccount();
        this.showModalLogout = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.getStoreStaffs();
  },
};
</script>
<style lang="scss" scoped></style>
